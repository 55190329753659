import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agens',
  templateUrl: './agens.component.html',
  styleUrls: ['./agens.component.css']
})
export class AgensComponent implements OnInit {
  //  tslint:disable:max-line-length
  text1 = 'agens (latein: das handelnde, darstellende, führende, tuende, verfahrende, vorgehende, wirkende) - agens ist der wirkende teil, oder das wirksame etwas - es ist das, was dafür sorgt, dass etwas geschieht - agens ist die treibende oder wirkende kraft, die etwas auslöst, verursacht, schafft…';
  text2 = 'agens lässt sich auch englisch aussprechen und mit „age“ in so ziemlich jeder hinsicht assoziieren - besonders passend scheinen interpretationen auf eine zeitloses geschehen hin zu sein…';
  text3 = 'das projekt agens ist eher eine huldigung der kräfte, die immer wieder für die entstehung wunderbarer musik sorgen, als selbst auslöser einer bewegung sein zu wollen - das projekt agens freut sich über die wunderbare wirkende kraft von musik…';
  text4 = 'danke an „neil stephenson“ für sein wunderbares buch „amalthea“, das die inspiration für die namensgebung des projekts agens lieferte, wenngleich sich der anspruch des projekts nicht in vergleichbaren dimensionen oder sphären ansiedelt…';
  text5 = 'wenn es da draußen nur hin und wieder jemanden gibt, dem dieses projekt ein paar schöne momente gibt, so ist dies bereits anlass und antrieb genug, daran weiter zu arbeiten und dafür große dankbarkeit zu verspüren';
  currentText: string;
  currentTextid: number;
  line1length = 20;
  line1: string;
  line2length = 30;
  line2: string;
  line3length = 40;
  line3: string;
  biglength = 50;
  linerest: string[];

  constructor() { }

  ngOnInit() {
    this.currentTextid = 0;
    this.currentText = this.text1;
    // this.currentTextToLines();
  }
  nextText() {
    this.currentTextid++;
    if (this.currentTextid === 1) {
      this.currentText = this.text2;
    } else if (this.currentTextid === 2) {
      this.currentText = this.text3;
    } else if (this.currentTextid === 3) {
      this.currentText = this.text4;
    } else if (this.currentTextid === 4) {
      this.currentText = this.text5;
    } else {
      // this.currentText = this.text1;
      // this.currentTextid = 0;
    }
    // this.currentTextToLines();
  }
  currentTextToLines() {
    let returnText = '';
    let checkText = '';
    const text = [];
    text.push(this.currentText.split(' '));
    for (const s in text) {
      if (typeof s === 'string') {
        checkText = returnText + ' ' + s;
        console.log(checkText);
        if (checkText.length >= this.line1length) {
          this.line1 = returnText;
        } else {
          returnText = checkText;
        }
      }
    }
  }
}
