import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
  state = 'default';
  constructor() { }

  ngOnInit() {
  }
  click() {
    this.state = 'banana';
  }

  public show(element: string) {
    document.getElementById(element).style.visibility = 'visible';
  }

  public hide(element: string) {
    document.getElementById(element).style.visibility = 'hidden';
  }
// send the message and get a callback with an error or details of the message that was sent
}
