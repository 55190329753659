import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visual',
  templateUrl: './visual.component.html',
  styleUrls: ['./visual.component.css']
})
export class VisualComponent implements OnInit {
  public lines: string[] = [
    'agens - wie mag das sein',
    'I - exposition',
    'II - variation',
    'III - meditation',
    'IV - kontemplation',
    'V - substitution',
    'VI - sublimation',
  ];

  constructor() { }

  ngOnInit() {
  }

  open(url: string) {
    window.open(url);
  }
}
