import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-current',
  templateUrl: './current.component.html',
  styleUrls: ['./current.component.css']
})
export class CurrentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public show(element: string) {
    document.getElementById(element).style.visibility = 'visible';
  }

  public hide(element: string) {
    document.getElementById(element).style.visibility = 'hidden';
  }
}
