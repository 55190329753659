import { Component, ElementRef, ViewChild } from '@angular/core';
import { Renderer2 } from '@angular/core';

const regions = [{ minHue: 15, maxHue: 45 }, { minHue: 245, maxHue: 300 }, { minHue: 210, maxHue: 160 }, { minHue: 310, maxHue: 360 }]

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public ctx;
  public pixels = [];
  minHue = regions[0].minHue;
  maxHue = regions[0].maxHue;
  time = Date.now();
  nextSwapIn = Math.round(Math.random() * 15000 + 5000);
  // its important myCanvas matches the variable name in the template
  @ViewChild('myCanvas')
  canvas: ElementRef;

  constructor(private renderer: Renderer2) {
    this.resize();
    this.renderer.setStyle(document.body, 'background-image', 'url(./../assets/bg.jpg)');
    this.renderer.setStyle(document.body, 'background-repeat', 'no-repeat');
    this.renderer.setStyle(document.body, 'background-position', 'center');
    this.renderer.setStyle(document.body, 'background-attachment', 'fixed');
    this.renderer.setStyle(document.body, '-webkit-background-size', 'cover');

  }
  ngAfterViewInit(): void {
    const nextCanvas = (<HTMLCanvasElement>this.canvas.nativeElement)
    console.log(nextCanvas)
    if (nextCanvas) {
      this.ctx = nextCanvas.getContext('2d');
      this.pixels = [
        new Pixel(0, 0, this.minHue),
        new Pixel(1, 0, this.maxHue),
        new Pixel(0, 1, this.minHue),
        new Pixel(1, 1, this.maxHue),
        // pixels for 9x9
        new Pixel(2, 2, this.minHue),
        new Pixel(2, 1, this.maxHue),
        new Pixel(1, 2, this.minHue),
        new Pixel(0, 2, this.maxHue),
        new Pixel(2, 0, this.minHue),

      ];
      this.animate();
    }
  }
  resize() {
    const height = window.innerHeight;
    this.renderer.setStyle(document.body, 'max-height', height + 'px');
  }
  animate() {
    this.pixels.forEach((pixel) => {
      pixel.update(this.minHue, this.maxHue);
      pixel.render(this.ctx);
    });
    if (Date.now() - this.time > this.nextSwapIn) {
      this.time = Date.now();
      this.nextSwapIn = Math.round(Math.random() * 15000 + 5000);
      this.pixels.forEach((pixel) => {
        pixel.updateVelocity();
      })
      if (Math.random() > 0.5) {
        const shift = Math.random() * 20 + 20;
        if (Math.random() > 0.5) {
          this.maxHue += shift;
          this.minHue += shift;
        } else {
          this.maxHue -= shift;
          this.minHue -= shift;
        }
      } else {
        const seed = Math.round(Math.random() * (regions.length - 1));
        this.minHue = regions[seed].minHue;
        this.maxHue = regions[seed].maxHue;
      }
    }
    requestAnimationFrame(() => {
      const that = this;
      that.animate()
    });
  }

}

class Pixel {
  x = 0;
  y = 0;
  hue = 0;
  direction = 1;
  velocity = 0;

  constructor(x, y, hue?) {
    this.x = x;
    this.y = y;
    this.hue = hue || Math.floor(Math.random() * 360);
    this.velocity = (Math.random() * 10 + 5) * 0.005;
  }

  update = function (minHue, maxHue) {
    const change = this.velocity * this.direction;
    const nextHue = this.hue + change;
    if (nextHue < minHue) {
      this.direction = 1;
      this.hue = nextHue;
    } else if (nextHue > maxHue) {
      this.direction = -1;
      this.hue = nextHue;
    }
    else {
      this.hue = nextHue;

    }
  };

  updateVelocity = function () {
    this.velocity = (Math.random() * 10 + 5) * 0.005;
  }

  render = function (ctx) {
    var hue = Math.round(this.hue);
    ctx.fillStyle = 'hsl(' + hue + ', 100%, 50% )';
    ctx.fillRect(this.x, this.y, 1, 1);
  }
}
