import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-band',
  templateUrl: './band.component.html',
  styleUrls: ['./band.component.css']
})
export class BandComponent implements OnInit {
  private filltext = '';
  private filepath = '';

  constructor() { }

  ngOnInit() {
  }

  mover(input: string) {
    console.log('mouseover');
    this.filltext = 'test';
    this.filepath = './../assets/placeholder.png';
    if (input === 'bass') {
      this.filltext = 'bruno';
    } else if (input === 'gitarre') {
      this.filltext = 'peer';
    } else if (input === 'keyboard') {
      this.filltext = 'robert';
    } else if (input === 'schlagzeug') {
      this.filltext = 'marcus';
    } else if (input === 'vocals') {
      this.filltext = 'n n';
    } else {
      this.filltext = '';
      this.filepath = '';
    }
  }
}
