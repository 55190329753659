import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-duo',
  templateUrl: './duo.component.html',
  styleUrls: ['./duo.component.css']
})
export class DuoComponent implements OnInit {

  public isReleased = new Date('10/SEP/2023').getTime() < Date.now()

  constructor() { }

  ngOnInit() {
  }

}
